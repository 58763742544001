import React, { useState } from "react";

export default function PlAskedQuestions() {
    return (
        <div id="support">
            <h2 className="my-3 text-3xl font-extrabold tracking-tight text-gray-900 text-center">Топ 10 вопросов</h2>
            <div className="w-full px-8 mx-auto mt-20 space-y-2 shadow lg:max-w-md">
                <details className="p-4 rounded-lg">
                    <summary className="font-semibold">1. Где и как размещать видео?</summary>
                    <div className="mt-3">
                        <p className="text-sm leading-6 text-gray-600">
                            Все видеоуроки загружаются сперва на видеохостинги YouTube или Vimeo, затем на платформу.
                        </p>
                    </div>
                </details>
                <details className="p-4 rounded-lg">
                    <summary className="font-semibold">
                        2. Вебинары можно проводить?
                    </summary>
                    <div className="mt-3">
                        <p className="text-sm leading-6 text-gray-600">
                            Можно проводить трансляции с помощью Zoom/Google Classroom
                        </p>
                    </div>
                </details>
                <details className="p-4 rounded-lg">
                    <summary className="font-semibold">
                        3. Смогу я сам заливать курс? Легко в использовании?
                    </summary>
                    <div className="mt-3">
                        <p className="text-sm leading-6 text-gray-600">
                            Да, платформа удобна в использовании. Так же есть видеоинструкции для загрузки курсов.
                        </p>
                    </div>
                </details>
                <details className="p-4 rounded-lg">
                    <summary className="font-semibold">
                        4. На Платформе есть запрет на запись экрана?
                    </summary>
                    <div className="mt-3">
                        <p className="text-sm leading-6 text-gray-600">
                            Видеоплеер курсов полностью защищен. Мобильное приложение платформы защищена от записи экранов и скриншота.
                        </p>
                    </div>
                </details>
            </div>
        </div>
    );
}