import { HashLink as Link } from "react-router-hash-link";

const PlMainInfo = () => {
  return (
    <section className="bg-white pt-6">
      <div className="max-w-screen-xl px-4 mx-auto flex lg:flex-row flex-col-reverse lg:gap-8 xl:gap-0 lg:pt-8 lg:grid-cols-12 mt-10">
        <div className="mr-auto place-self-center lg:col-span-7 mt-8">
          <h1 className="max-w-2xl mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-3xl xl:text-5xl">
            Создавайте и продавайте <br />
            свои курсы легко, удобно и <br />
            быстро
          </h1>
          <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
            Попробуйте демо версию совершенно бесплатно и убедитесь сами
          </p>
          <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
            <Link
              to={"/trial-page"}
              className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center bg-teal-600 text-white border border-teal-200 rounded-lg sm:w-auto hover:bg-teal-700 focus:ring-4 focus:ring-teal-300"
            >
              Попробовать 5 дней бесплатно
            </Link>
            <a
              href="https://demo.topcourse.kz"
              target="_blank"
              className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center border border-teal-600 rounded-lg sm:w-auto"
            >
              Демо версия
            </a>
          </div>
        </div>
        <div className="lg:mt-0 lg:col-span-5 lg:block">
          <img src="/woman.png" alt="mainImg" />
        </div>
      </div>
    </section>
  );
};

export default PlMainInfo;
