import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { HashLink as Link } from "react-router-hash-link";

export default function PlHeader({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <nav className="flex flex-wrap items-center justify-between px-2 py-3 mb-3 fixed bg-white w-full">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <Link
            to="/"
            className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
          >
            Topcourse.kz
          </Link>

          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <GiHamburgerMenu />
          </button>
        </div>
        <div
          className={
            "lg:flex flex-grow items-center" +
            (navbarOpen ? " flex" : " hidden")
          }
          id="example-navbar-danger"
        >
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className="nav-item">
              <Link
                to={"/#advantage"}
                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snughover:opacity-75"
              >
                Преимущества
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/#prices"}
                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snughover:opacity-75"
              >
                Тарифы
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/#support"}
                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snughover:opacity-75"
              >
                Частые вопросы
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
