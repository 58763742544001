import PlMainInfo from "./components/PlMainInfo";
import PlPrices from "./components/PlPrices";
import PlFooter from "./components/PlFooter";
import PlAskedQuestions from "./components/PlAskedQuestions";
import PlAdvantage from "./components/PlAdvantage";
import PlHeader from "./components/PlHeader";

const PlatformLanding = () => {
  return (
    <>
      <PlHeader />
      <PlMainInfo />
      <PlAdvantage />
      <PlPrices />
      <PlAskedQuestions />
      <PlFooter />
    </>
  );
};

export default PlatformLanding;
